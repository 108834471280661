import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyC5UbIuKW07J5zV4i9o1P_0q1WqZ3OWigk",
    authDomain: "onlinefreewatch-e6d43.firebaseapp.com",
    projectId: "onlinefreewatch-e6d43",
    storageBucket: "onlinefreewatch-e6d43.appspot.com",
    messagingSenderId: "307535060958",
    appId: "1:307535060958:web:812807040912848032074f",
    measurementId: "G-NL9V68RQJ9"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;
